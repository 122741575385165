import "./App.css";
import Footer from "./modules/AppFooter/Footer";
import Header from "./modules/AppHeader/Header";
import DeleteUserData from "./modules/DeleteUserData";
import Landing from "./modules/Landing/Landing";
import PrivacyPolicy from "./modules/PrivacyPolicy";
import { Routes, Route } from "react-router-dom";
import TermsAndConditions from "./modules/TermsAndConditions";
import RefundPolicy from "./modules/RefundPolicy";
import Faqs from "./modules/Faqs";
import Pricing from "./modules/Pricing";
import HostAgreement from "./modules/HostAgreement";

export const scrollToTop = () => {
  const body = document.querySelector("#root");
  body.scrollIntoView(
    {
      behavior: "smooth",
    },
    500
  );
};

function App() {
  return (
    <div className="App">
      <Header />
      <header className="App-header">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/remove-data" element={<DeleteUserData />} />
          <Route
            path="/cancellation-and-refund-policy"
            element={<RefundPolicy />}
          />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/host-agreement" element={<HostAgreement />} />
        </Routes>
        <Footer />
      </header>
    </div>
  );
}

export default App;
